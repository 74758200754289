(function ($) {
  $(document).ready(function () {

    $('a[href^="#"]:not(a.menu-trigger,#back_to_top a)').on('click', function (e) {

      var $href = $(this).attr('href'),
        $tgt = $($href == "#" || $href == "" ? 'html' : $href),
        $pos = $tgt.offset().top,
        $correctH = parseInt($('.header_top').outerHeight() + 10);
      $("html, body").animate({
        scrollTop: $pos - $correctH
      }, 500);
      e.preventDefault();

    });



    var mql = window.matchMedia("(min-width : 768px)");
    mql.addListener(menuClose);
    menuClose(mql);

    function menuClose(mql) {
      if (mql.matches) {
        $("#menu").removeClass('menu_open');
        $('.menu-trigger').removeClass('triggeractive');
      }
    }

    $('.slider').slick({
      arrows: false,
      autoplay: true,
      fade: true,
      speed: 500,
      pauseOnDotsHover: false
    });
    
    






    // gmap iframe
    $('.gmap').click(function () {
      $('.gmap iframe').css("pointer-events", "auto");
    });

  });



  
  
})(jQuery);

window.addEventListener('load',function(){
  //document.getElementById('loading').classList.add('finished');
})



window.addEventListener('DOMContentLoaded', function () {
  
  //set noopener
  
  var $links = document.querySelectorAll('a[target="_blank"]');
  [].forEach.call($links,function(link){
    
    if(link.hasAttribute('rel') === false){
      link.setAttribute('rel','noopener');
    }

  })
  



  //hmb
  
  var toggle = document.getElementById('toggle');
  var menu = document.getElementById('menu');
  var gnav = document.querySelector('.site_navigation');
  var menuTrigger = document.querySelector('.menu-trigger');
  var menuLists = document.querySelectorAll('#menu li a');
  var body = document.querySelector('body');
  toggle.addEventListener('click', function (e) {
    menu.style.transition = "all 0.5s ease-in-out";
    e.preventDefault();
    menu.classList.toggle('menu_open')
    toggle.classList.toggle('active');
    body.classList.toggle('menuopen');
    gnav.classList.toggle('active');
    menuTrigger.classList.toggle('triggeractive');
  });
  
  window.addEventListener('resize',function(){
    if(window.innerWidth >= 768){
      menu.style.transition = "none";
      body.classList.remove('menuopen')
    }
  });
  
  menu.addEventListener('click',function(e){
    
    if(window.innerWidth < 768){
      e.preventDefault();
      menu.classList.remove('menu_open')
      toggle.classList.remove('active');
      body.classList.remove('menuopen');
      gnav.classList.remove('active');
      menuTrigger.classList.remove('triggeractive');
    }
  });


  
  var snsList = document.getElementById('sns_list');
  var snsHtml = snsList.innerHTML;
  
  var menuWrap = document.querySelector('#menu .menu_wrap');
  var li = document.createElement('li');
  li.classList.add('sp_only');
  li.classList.add('sns');
  li.innerHTML = snsHtml
  menuWrap.appendChild(li);
  

  menuWrap.addEventListener('click',function(e){
    e.stopPropagation();
  })
  objectFitImages('.ofi');
  
  
  
  //月別アーカイブのタイトル変更v
  
  const pageUrl = location.href;
  const archiveCat = document.querySelector('.archive_cat');
  if(archiveCat !== null){
    if(pageUrl.includes('cat_slug=diary')){
      archiveCat.innerHTML = '院長ブログ'
    }else if(pageUrl.includes('cat_slug=information')){
      archiveCat.innerHTML = 'お知らせ'
    }
  }
  
});





window.addEventListener('load',function(){
  const loading = document.querySelector('#loading');
  loading.classList.add('finished');
})



//fixed

const menu = document.querySelector('#menu');
const mainvisual = document.querySelector('.mainvisual');

function headerFix(){

  var scr = window.pageYOffset;
  var scrPosition;

  if(document.body.classList.contains('home')){
    scrPosition = mainvisual.getBoundingClientRect().top + mainvisual.offsetHeight + window.pageYOffset;
  }else{
    scrPosition = window.innerHeight / 5
  }
  


  if(scr > scrPosition){
    menu.classList.add('fixed');
  }else{
    menu.classList.remove('fixed');
  }
}

window.addEventListener('scroll',headerFix);
window.addEventListener('DOMContentLoaded',headerFix);

